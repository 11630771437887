/* General styles and resets */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Love Ya Like A Sister', cursive, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; /* Smoothing entries for better font rendering across different browsers */
  background-color: #259FA7;
  height: 100%;
}

/* Memory Game styles */
.memory-game-options {
  display: flex;
  width: 100%;
  max-width: 1200px;
  height: 100vh;
  margin: 0 auto;
}

.memory-game-board {
  display: flex;
  width: 100%;
  max-width: 1200px;
  height: 100vh;
  margin: 0 auto;
}

.logo-container-home, .logo-container-game { /* Container for the header */
  flex: 0 0 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.logo-container-home {
  margin-left: 25px;
}

.logo-container-game {
  margin-right: 25px;
}

.header-container-home, .header-container-game {
  display: flex;
  justify-content: center;
  width: 100%;
}
/* Home page header */
.header-container-home {
  margin-right: 120px; 
  margin-bottom: 20px;
  }
/* Game page header */
.header-container-game {
  align-items: left;
  }
    
.header-home, .header-game {
  font-family: 'Love Ya Like A Sister', cursive;
  background: transparent;
  text-align: center;
  padding: 10px 20px;
  font-size: 5em;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(to bottom, #FFE794 0%, #FFEFFF 100%);
  margin-top: 25px; /* Move the header up */
  letter-spacing: normal; /* Reset letter spacing */
  word-spacing: normal; /* Reset word spacing */
  line-height: normal; /* Reset line height */
  font-weight: normal; /* Reset font weight */
}

.header-game {
  font-family: 'Love Ya Like A Sister', cursive;
  font-size: 5em;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -3px; /* Negative margin to pull it up */
}

.game-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-right: 380px;
}

.game-board {
  background-color: #FFDC62;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #666;
  margin-bottom: 20px;
}

.grid {
  display: grid;
  gap: 11px;
}

.card-facedown, .card-faceup {
  height: 110px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #145E69; 
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.card-facedown {
  background-color: #FFFACD; /* Light yellow when face down */
  font-size: 0; /* Hide text when face down */
}

.card-faceup {
  background-color: #FFFFFF; /* White background when face up */
  font-size: 2.3em; /* Show text when face up */
}

.card-image-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.card-image-wrapper img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.game-controls {
  display: flex;
  justify-content: center;
  padding: 2px;
  gap: 60px; /* Spacing between buttons */
  margin-top: 20px; /* Spacing between game board and buttons */
}

/* Common styles for all buttons */
.button {
  border: none;
  cursor: pointer;
  border-radius: 7px;
  font-family: 'Ligconsolata', monospace;
  }
  
.button.reset {
  background-color: #FF9EA4; 
  height: 55px;
  width: 150px;
  padding: 10px 20px;
  margin: 10px;
  font-size: 1.3em
}

.button.play-again, .button.home {
  flex: 1;
  background-color: #8EFFE4;
  max-width: 150px;
  padding: 10px 5px; 
  font-size: 1em;
  height: 50px; 
}

.button-group-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px; /* Space between buttons */
  margin-top: 20px; /* Space above the button group */
}

.game-over-modal {
  position: fixed;
  top: 46%;
  left: 52%;
  transform: translate(-50%, -50%);
  height: 250px;
  width: 400px;
  margin-top: 0px;
  text-align: center;
  padding: 20px 20px;
  font-family: 'Love Ya Like A Sister', cursive;
  font-size: 1.2em;
  background-color: white; /* Ensures the modal has a visible background */
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visibility */
  z-index: 1000; /* Ensure the modal appears above other content */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 70%, 0.4); /* Black with 50% opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Cover everything below the modal */
}

.footer {
  position: fixed;
  bottom: 10px; /* Space from the bottom */
  right: 15px; /* Space from the right edge */
  background: transparent;
  color: #8EFFE4;
  text-align: right;
  padding: 5px 10px;
  font-size: 0.9em; 
  font-family: 'Love Ya Like A Sister', cursive; 
}

/* Home Page Styles */
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  text-align: center;
  padding: 20px;
}

.home-header {
  font-family: 'Love Ya Like A Sister', cursive;
  font-size: 5em;
  margin-bottom: 20px;
  color: #FFE794;
  background: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.home-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-cards {
  display: flex;
  flex-direction: row;
  gap: 7px; /* Space between cards */
  align-items: center;
  margin-right: 120px; /* Push home-cards away from the options cards (also applied to header-container-home) */
  margin-bottom: 20px;
}

.home-card-faceup, .home-card-facedown {
  font-family: 'Love Ya Like A Sister', cursive;
  border: 1px solid #145E69; 
  border-radius: 10px;
  padding: 20px;
  font-size: 1.6em;
  width: 220px;
  height: 280px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.home-card-faceup {
  background-color: #FFFFFF;
  transform: rotate(15deg);
  z-index: 1; /* Lower z-index to appear below */
  position: relative; /* Needed to apply z-index */
}

.home-card-facedown {
  background-color: #FFFACD;
  transform: rotate(-15deg);
  z-index: 2; /* Higher z-index to appear on top */
  position: relative; /* Needed to apply z-index */
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.options-subheader {
  font-family: 'Love Ya Like A Sister', cursive;
  font-size: 1.5em;
  color: #FFFFFF;
  margin-top: 15px;
}

.button-group {
  display: flex;
  gap: 14px;
  padding: 10px;
  gap: 20px;
}

.card-option-button, .grid-option-button {
  cursor: pointer;
  border: 1px solid #145E69;
  border-radius: 10px;
  background-color: #8EFFE4;
}

.card-option-button {
  height: 110px;
  width: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid-option-button {
  padding: 10px 20px;
  font-size: 1.2em;
}

.card-option-button.active, .grid-option-button.active {
  background-color: #FFFACD;
  border: 2px solid #FFEA61;
}

.play {
  background-color: #FF9EA4;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  height: 55px;
  width: 130px;
  cursor: pointer;
  font-size: 1.4em;
  margin-top: 50px;
  margin-bottom: 40px;
}
.button.reset {
  background-color: #FF9EA4; 
  height: 55px;
  width: 150px;
  padding: 10px 20px;
  margin: 10px;
  font-size: 1.3em
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .memory-game-board, .memory-game-options {
    flex-direction: column;
  }
  
  .logo-container-home, .logo-container-game {
    flex: 0 0 auto;
    width: 100%;
    max-width: none;
  }
  
  .game-container, .home-content {
    width: 100%;
  }

  .game-container {
    padding-top: 5px;
  }

  .home-header {
    font-size: 3em;
  }

  .home-content {
    flex-direction: column;
    align-items: center;
  }

  .home-cards {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .options {
    width: 100%;
  }

  .button-group {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer {
    padding-top: 100px;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 767px) {
  .logo-container-home {
    max-width: 80%; /* Restrict max width */
  }

  .header-container-home {
    margin-right: 5px; 
    margin-top: 10px;
    margin-bottom: 5px;
    }
      
  .header-home, .header-game {
    padding: 10px 20px;
    font-size: 5em;
    margin-top: 25px; /* Move the header up */
  }

  .home-cards {
    width: 90%; 
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto; /* Center the cards container */
    position: relative; 
    height: 220px; /* Fixed height to contain cards */
  }

  .home-card-faceup, .home-card-facedown {
    font-size: 1em; /* Slightly smaller font */
    width: 125px; /* Reduced width */
    height: 160px; /* Reduced height */
    position: absolute; /* Position cards absolutely within .home-cards */
    top: 15px; /* Top position */
  }

  .home-card-faceup {
    right: calc(35% - 70px); /* Position slightly to the right */
    transform: rotate(15deg); 
    z-index: 1;
  }
  
  .home-card-facedown {
    left: calc(35% - 70px); /* Position slightly to the left */
    transform: rotate(-15deg); 
    z-index: 2;
  }

  .options, .logo-container {
    margin-left: 5%; /* Slight left margin for better centering */
  }

  .play {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 1.3em;
    height: 45px;
    width: 90px;
  }
  
  /* Game page adaptations */
  .memory-game-board {
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 10px;
  }
  
  .logo-container-game {
    margin-right: 0;
    padding: 10px;
  }
  
  .header-container-game {
    flex-shrink: 0;
  }

  .header-game {
    font-size: 5em;
    margin-top: 40px;
    margin-bottom: -10px;
    margin-right: 20px;
  }

  .game-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 90vw;
    margin: 20px;
  }

  .game-board {
    width: 100%;
    aspect-ratio: 1 / 1;
    padding: 5px;
  }
    
  .grid {
    display: grid;
    width: 100%;
    height: 100%;
    gap: 5px;
    /* Create a grid with a number of rows equal to the value of --grid-rows 
    (or 4 if that variable isn't set), 
    and make each row take up an equal fraction of the available space. */
    grid-template-columns: repeat(var(--grid-columns, 4), 1fr);  /* 1fr = 1 fraction unit. It tells the grid to distribute available space equally among all rows. */
    grid-template-rows: repeat(var(--grid-rows, 4), 1fr);
  }

  .card-facedown, .card-faceup {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;
  }

  .footer {
    font-size: 0.7em;
    padding: 5px 10px;
  }

  /* GameOverModal styles */
  .game-over-modal {
    position: absolute;
    top: 30%;
    left: 27%;
    transform: translate(-50%, -48%);
    width: 80%;
    max-width: 300px;
    height: auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001; /* Ensure it's above the overlay */
  }

  .game-over-modal h2 {
    margin-bottom: 10px;
  }

  .game-over-modal div {
    font-size: 1em;
    margin-bottom: 5px;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* High z-index to appear above other elements */
  }

  .button-group-modal {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
    width: 100%;
  }

  .button-group-modal .button {
    width: 100%;
    padding: 10px;
    font-size: 0.9em;
  }

  /* Font size adjustments */
  body {
    font-size: 14px;
  }

  h1 {
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.5em;
  }
}